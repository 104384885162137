// types
import type {
  FullCalendarEvent,
  Student
} from '@revolutionprep/types'

// utilities
import { isEmpty } from '@revolutionprep/utils'
import {
  addMinutes,
  differenceInMinutes,
  isBefore,
  isEqual,
  startOfWeek as getStartOfWeek
} from 'date-fns'
import { format } from 'date-fns-tz'

// nuxt
import { useNuxtApp } from '#app'

// types
interface TimeInterval {
  start: Date
  end: Date
}

export function useCalendarStudentAvailability () {
  /**
   * nuxt app
   * ==================================================================
   */
  const { $actor } = useNuxtApp()

  /**
   * state
   * ==================================================================
   */
  const availabilityValidationErrors = ref<Record<string, string>>({})
  const calendarAvailabilityItems = ref<FullCalendarEvent[]>([])
  const duration = ref(0)
  const frequency = ref(3)
  const isProcessing = ref(false)

  /**
   * stores
   * ==================================================================
   */
  const studentStore = useStudentStore()

  const tutorPackageTranscriptStore = useTutorPackageTranscriptStore()
  const { tutorPackageTranscript } = storeToRefs(tutorPackageTranscriptStore)

  /**
   * computed
   * ==================================================================
   */
  const actor = computed(() => {
    return $actor.core.actor.value as Student
  })

  const actorId = computed(() => {
    return $actor.core.actorId.value
  })

  const calendarAvailabilitiesJson = computed(() => {
    // map calendar items to availabilities JSON
    return calendarAvailabilityItems.value.map((calendarItem) => {
      const duration = differenceInMinutes(
        calendarItem.end as Date,
        calendarItem.start as Date
      )
      return {
        day: format(new Date(calendarItem.start), 'EEEE'),
        duration,
        time: format(new Date(calendarItem.start), 'HH:mm'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    })
  })

  const defaultDuration = computed(() => {
    let duration
    if (
      actor.value?.grade &&
      actor.value.grade >= 9
    ) {
      duration = 60
    } else {
      duration = 30
    }
    return duration
  })

  const isAvailabilityComplete = computed(() => {
    return Boolean(
      calendarAvailabilityItems.value.length &&
      !hasErrors.value
    )
  })

  const mapDaysOfWeek = computed(() => {
    return {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6
    } as { [key: string]: number }
  })

  const pace = computed(() => {
    return tutorPackageTranscript.value?.pace || 1
  })

  const progressBarLabel = computed(() => {
    const totalSelectedHours = totalSelectedDuration.value / 60
    const requiredTotalHours = requiredTotalDuration.value / 60
    return `${
      totalSelectedHours.toFixed(2)
    } of ${
      requiredTotalHours.toFixed(2)
    } hours selected`
  })

  const requiredDuration = computed(() => {
    return duration.value + 60
  })

  const requiredFrequency = computed(() => {
    return Math.min(frequency.value + 1, 7) // maximum of 7 days in a week
  })

  const requiredFrequencyInWords = computed(() => {
    const numbersToWords = {
      1: 'one',
      2: 'two',
      3: 'three',
      4: 'four',
      5: 'five',
      6: 'six',
      7: 'seven'
    } as { [key: number]: string }
    return numbersToWords[requiredFrequency.value]
  })

  const requiredTotalDuration = computed(() => {
    return requiredDuration.value * requiredFrequency.value
  })

  const hasErrors = computed(() => {
    return !isEmpty(availabilityValidationErrors.value)
  })

  const startOfWeek = computed(() => {
    return getStartOfWeek(new Date())
  })

  const studentAvailabilitiesJson = computed(() => {
    return actor.value?.availabilitiesJson || []
  })

  const timezone = computed(() => {
    return new Date()
      .toLocaleString('en', { timeZoneName: 'short' })
      .split(' ')
      .pop() as string
  })

  const totalSelectedDuration = computed(() => {
    return calendarAvailabilityItems.value.reduce((
      totalHours,
      calendarItem
    ) => {
      if (calendarItem.end) {
        totalHours += differenceInMinutes(
          calendarItem.end as Date,
          calendarItem.start as Date
        )
      }
      return totalHours
    }, 0)
  })

  /**
   * watchers
   * ==================================================================
   */
  watch(calendarAvailabilityItems, (newVal) => {
    checkRequiredFrequency(newVal)
    checkRequiredDurationPerAvailability(newVal)
  })

  /**
   * methods
   * ==================================================================
   */
  function checkRequiredDurationPerAvailability (
    calendarAvailabilityItems: FullCalendarEvent[]
  ) {
    // "We need at least X minutes per availability"
    const hasInsufficientDuration = findInsufficientDuration(
      calendarAvailabilityItems
    )
    if (hasInsufficientDuration) {
      availabilityValidationErrors.value = {
        ...availabilityValidationErrors.value,
        requiredDuration:
          `We need at least <b>${
            requiredDuration.value
          }</b> minutes per availability`
      }
    } else {
      availabilityValidationErrors.value = {
        ...availabilityValidationErrors.value
      }
      delete availabilityValidationErrors.value.requiredDuration
    }
  }

  function checkRequiredFrequency (
    calendarAvailabilityItems: FullCalendarEvent[]
  ) {
    // "We need at least X days of availability,
    // but only Y days are selected."
    const selectedFrequency = getSelectedFrequency(
      calendarAvailabilityItems
    )
    if (selectedFrequency < requiredFrequency.value) {
      const singularOrPluralDays = selectedFrequency === 1
        ? 'day'
        : 'days'
      const singularOrPluralToBe = selectedFrequency === 1
        ? 'is'
        : 'are'
      availabilityValidationErrors.value = {
        ...availabilityValidationErrors.value,
        requiredFrequency:
          `We need at least <b>${
            requiredFrequency.value
          } days</b> of availability, but ${
            selectedFrequency >= 1 ? 'only' : ''
          } <b>${
            selectedFrequency
          } ${
            singularOrPluralDays
          }</b> ${
            singularOrPluralToBe
          } selected.`
      }
    } else {
      availabilityValidationErrors.value = {
        ...availabilityValidationErrors.value
      }
      delete availabilityValidationErrors.value.requiredFrequency
    }
  }

  function divideIntoIntervals (
    calendarItem: FullCalendarEvent,
    duration: number
  ) {
    const timeIntervals: TimeInterval[] = []
    if (calendarItem.end) {
      let start = new Date(calendarItem.start)
      const end = new Date(calendarItem.end)
      while (
        isBefore(addMinutes(start, duration), end) ||
        isEqual(addMinutes(start, duration), end)
      ) {
        timeIntervals.push({
          start,
          end: addMinutes(start, duration)
        })
        start = addMinutes(start, duration)
      }
    }
    return timeIntervals
  }

  function findInsufficientDuration (
    calendarAvailabilityItems: FullCalendarEvent[]
  ) {
    return calendarAvailabilityItems.some((calendarItem) => {
      const duration = differenceInMinutes(
        calendarItem.end as Date,
        calendarItem.start as Date
      )
      return duration < requiredDuration.value
    })
  }

  function getSelectedFrequency (
    calendarAvailabilityItems: FullCalendarEvent[]
  ) {
    const distinctDaysOfWeek = new Set()
    calendarAvailabilityItems.forEach((calendarItem) => {
      const dayOfWeek = format(new Date(calendarItem.start), 'EEEE')
      if (!distinctDaysOfWeek.has(dayOfWeek)) {
        distinctDaysOfWeek.add(dayOfWeek)
      }
    })
    return distinctDaysOfWeek.size
  }

  function updateCalendarItems (items: FullCalendarEvent[]) {
    calendarAvailabilityItems.value = items
  }

  async function updateStudentAvailabilities () {
    if (!actorId.value) {
      throw new Error('Actor ID not found. Please refresh the page.')
    }
    await studentStore.update(
      actorId.value,
      {
        availabilities_json: JSON.stringify(calendarAvailabilitiesJson.value)
      },
      {
        params: {
          include: 'availabilitiesJson,school'
        }
      }
    )
  }

  return {
    actor,
    actorId,
    availabilityValidationErrors,
    calendarAvailabilitiesJson,
    calendarAvailabilityItems,
    checkRequiredDurationPerAvailability,
    checkRequiredFrequency,
    defaultDuration,
    divideIntoIntervals,
    duration,
    findInsufficientDuration,
    frequency,
    getSelectedFrequency,
    hasErrors,
    isAvailabilityComplete,
    isProcessing,
    mapDaysOfWeek,
    pace,
    progressBarLabel,
    requiredDuration,
    requiredFrequency,
    requiredFrequencyInWords,
    requiredTotalDuration,
    startOfWeek,
    studentAvailabilitiesJson,
    timezone,
    totalSelectedDuration,
    updateCalendarItems,
    updateStudentAvailabilities
  }
}
